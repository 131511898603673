import React, { useEffect } from "react";
import Me from "../images/me.jpg";
import "./css/about.css";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div>
      <div className="content" data-aos="fade-right">
        <div className="row">
          <div className="col-md-5 col-12 fl">
            <img className="me-pic" src={Me} alt="ME" />
          </div>
          <div className="col-md-7 col-12 fl mt-4">
            <h1 style={{ fontSize: "2.5rem", fontWeight: "600" }}>
              Azimjon Akbarjonov
            </h1>
            <p style={{ fontSize: "1.3rem" }}>
              I was born on July 8, 2003 in Uchkoprik district, Fergana region.
              My future dream is to make enough changes in the world of
              technology and introduce the Uzbek nation to the world.
            </p>
            <a
              className="btn btn-secondary"
              target={"_blank"}
              href="./cv.pdf"
              style={{ fontSize: "1.1rem" }}
            >
              Download CV <i className="bi bi-arrow-down-circle-fill"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
