import React, { useEffect } from "react";
import "./css/contact.css";
import Aos from "aos";
import "aos/dist/aos.css";
// import { send } from "../email";
const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="content contact-box" data-aos="zoom-out-left">
      <h1 className="text-center">Contact me</h1>
      <form className="row g-3">
        <div className="col-md-6">
          <label htmlFor="nameCon" className="form-label">
            Name
          </label>
          <input type="text" className="form-control" id="nameCon" />
        </div>
        <div className="col-md-6">
          <label htmlFor="emailCon" className="form-label">
            Email
          </label>
          <input type="email" className="form-control" id="emailCon" />
        </div>

        <div className="col-12">
          <label htmlFor="themeCon" className="form-label">
            Theme
          </label>
          <input type="text" className="form-control" id="themeCon" />
        </div>
        <div className="col-12">
          <label htmlFor="textCon" className="form-label">
            Text
          </label>
          <textarea
            type="text"
            className="form-control"
            id="textCon"
            placeholder="Write latter"
          />
        </div>
        <div className="col-12">
          <div className="d-grid">
            <button
              // onClick={send}
              type="button"
              className="btn btn-primary btn-block"
            >
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
