import React from "react";

const Footer = () => {
  return (
    <>
      <div className="footer" style={{ height: "5vh" }}>
        <p>
          © {new Date().getFullYear()}-2022 Made by{" "}
          <a target={"_blank"} href="https://t.me/azimjon_akbarjonov">
            Azimjon
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
