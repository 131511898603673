import React, { useEffect } from "react";
import "./css/home.css";
import Typewriter from "typewriter-effect";
import Me from "../images/me.jpg";
import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div>
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <div className="content" data-aos="fade-right">
          <div className="row">
            <div className="col-md-5 col-12 fl">
              <img className="me-pic" src={Me} alt="ME" />
            </div>
            <div
              className="col-md-7 col-12 fl mt-4"
              style={{ fontSize: "1.5rem", fontWeight: "600" }}
            >
              <h1>Azimjon Akbarjonov</h1>
              <h3 style={{ fontSize: "2rem", fontWeight: "600" }}>
                I am{" "}
                <Typewriter
                  options={{
                    strings: ["React dev", "Js dev "],
                    autoStart: true,
                    loop: true,
                  }}
                  f
                />
              </h3>
              <a
                className="btn btn-secondary"
                target={"_blank"}
                href="./cv.pdf"
                style={{ fontSize: "1.1rem" }}
              >
                Download CV <i className="bi bi-arrow-down-circle-fill"></i>
              </a>
              <div className="icons mt-4">
                <a
                  target={"_blank"}
                  href="https://t.me/azimjon_akbarjonov"
                  rel="noreferrer"
                >
                  <i className="bi bi-telegram"></i>
                </a>
                <a
                  target={"_blank"}
                  href="https://github.com/azimjon-exe"
                  rel="noreferrer"
                >
                  <i className="bi bi-github"></i>
                </a>
                <a
                  target={"_blank"}
                  href="https://linkedin.com/in/azimjonakbarjonov"
                  rel="noreferrer"
                >
                  <i className="bi bi-linkedin"></i>
                </a>
                <a
                  target={"_blank"}
                  href="https://twitter.com/AkbarjonovA"
                  rel="noreferrer"
                >
                  <i className="bi bi-twitter"></i>
                </a>
                <a
                  target={"_blank"}
                  href="https://Instagram.com/azimjon_akbarjonov"
                  rel="noreferrer"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  target={"_blank"}
                  href="https://www.youtube.com/@azimjonakbarjonov"
                  rel="noreferrer"
                >
                  <i className="bi bi-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
