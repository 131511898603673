import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid px-5">
          <Link
            className="navbar-brand"
            to="/"
            style={{ fontSize: "1.6rem", fontWeight: "600" }}
          >
            Azimjon Akbarjonov
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav me-auto mb-2 mb-lg-0"
              style={{ fontSize: "1.1rem", fontWeight: "600" }}
            >
              <li className="nav-item">
                <Link className="nav-link ho" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link ab" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link po" to="/portfolio">
                  Portfolio
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link bl" to="/blog">
                  Blog
                </Link>
              </li> */}
              <li className="nav-item">
                <Link className="nav-link co" to="/contact">
                  Contact me
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
