import React, { useEffect } from "react";
import "./css/portfolio.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Food from "../images/food.png";
import Tic from "../images/tic.png";
import Shop from "../images/shop.png";
import Mov from "../images/mov.png";
import Load from "../images/load.png";
import Twit from "../images/twit.png";
import Yoz from "../images/yoz.png";
import Bag from "../images/bag.png";
import Fish from "../images/fish.png";
import Ilab from "../images/ilab.png";

const Portfolio = () => {
  useEffect(() => {
    Aos.init({
      duration: 500,
      throttleDelay: 0,
      disableMutationObserver: false,
    });
  }, []);
  return (
    <div className="portfolio-box">
      <h1 className="text-center text-light mt-3">My Portfolio</h1>.
      <div className="container">
        <div className="row justify-content-center align-items-center g-2 pb-5 mb-5">
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-right"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Ilab} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Ilab.uz</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="react-b">React</div>
                  <div className="ts-b">TS</div>
                  <div className="api-b">API</div>
                </div>
                <p className="card-text">
                  Online store for a company importing laboratory equipment to
                  Uzbekistan
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  target={"_blank"}
                  href="https://ilab.uz/"
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-right"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Load} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Loadviser.group</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="js-b">JS</div>
                  <div className="php-b">PHP</div>
                </div>
                <p className="card-text">
                  This website was created for an American logistics company
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  target={"_blank"}
                  href="https://loadviser.group/"
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-down"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Tic} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Tic Tac Toe</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="react-b">React</div>
                </div>
                <p className="card-text">
                  On this website you can play x o game with your friends
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  target={"_blank"}
                  href="https://tictactoe-az.netlify.app/"
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Food} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Food recipe</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="react-b">React</div>
                  <div className="api-b">API</div>
                </div>
                <p className="card-text">
                  You can find more than 100 recipes on this website
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://food-recipe-az.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-right"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Shop} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Shop</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="react-b">React</div>
                  <div className="api-b">API</div>
                </div>
                <p className="card-text">
                  On this website, you can see that the ordering system of
                  online magazines has been developed
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://shop-az.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-up"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Mov} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Movies</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="react-b">React</div>
                  <div className="api-b">API</div>
                </div>
                <p className="card-text">
                  You can search for movies and series on this website (only
                  when they are released).
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://movies-azimjon.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Twit} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Twitter app</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="react-b">React</div>
                </div>
                <p className="card-text">
                  This website is designed for writing tweets as a practice only
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://twitter-az.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-right"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Yoz} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Yozyovonbaliqchilik.uz</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="js-b">JS</div>
                </div>
                <p className="card-text">
                  Project on the electronicization of fishing system of Yozyavon
                  district <b>(Frontend developer)</b>
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://yozyavonbaliqchilik.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-up"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Bag} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">BabdodAgroExpo.uz</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="js-b">JS</div>
                </div>
                <p className="card-text">
                  Project on Electrification of Agriculture of Baghdad District
                  <b>(Frontend developer)</b>
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://bagdodagroexpo.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div
              className="card"
              data-aos="fade-left"
              data-aos-anchor=".portfolio-box"
            >
              <img className="card-img-top" src={Fish} alt="Title" />
              <div className="card-body">
                <h4 className="card-title">Mister FISH crm</h4>
                <div className="tex">
                  <div className="html-b">HTML</div>
                  <div className="css-b">Css</div>
                  <div className="js-b">JS</div>
                </div>
                <p className="card-text">
                  CRM System for Mister Fish enterprise{" "}
                  <b>(Frontend developer)</b>
                </p>
                <a
                  className="portfolioLink btn btn-outline-dark"
                  href="https://misterfish-az.netlify.app/"
                  target={"_blank"}
                >
                  See web-site
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
